import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import logo from '../../Images/home4/SiteLogo.png'
import PropTypes from 'prop-types'
import Axios from 'axios'
import image from '../../Images/New/authimg.jpeg'
import { useDispatch } from 'react-redux'
import { UserLoginResponse } from '../../Redux/Actions/actions'
import Loading from '../../Components/Loading/Loader'
import { ApiEndPoint,AuthToken } from '../../Components/GlobalData/GlobalData'
import moment from 'moment'
import Select from 'react-select'
const Login = ({ setToken }) => {
  const authtoken=AuthToken();
  const [country, setCountry] = useState([])
  const [city, setCity] = useState([])
  const [showPassword, setShowPassword] = useState(false)
  const dispatch=useDispatch();
  const [userData, setUserData] = useState({ username: '', password: '' })
  const [signupData, setSignupData] = useState({
    title: 'MR',
    fname: '',
    lname: '',
    email: '',
    cname: '',
    caddress: '',
    country: null,
    phno: '',
    zcode: '',
    pass: ''
  })
  const [signUp, setsignUp] = useState(false)
  const [signUpCity, setsignUpCity] = useState('')
  const [loader, setLoader] = useState(false)
  const endpoint = ApiEndPoint()
  // const errorMessage = useSelector(state => state.auth.errorMessage);
  useEffect(() => {
    GetCountries()
  }, [])

  function handleChange (e) {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }
  function handleSignupChange (e) {
    setSignupData({ ...signupData, [e.target.name]: e.target.value })
  }
  const handleSignupChangeCountry=(option)=>{
    setSignupData({ ...signupData, country: option,phno:option.code })
    GetCountryCities(option.value)
  }
  function handleSignupChangeCity (e) {
    setsignUpCity(e.target.value)
  }
  const HandleLogin = async (e) => {
    e.preventDefault();
    const data = {
      token:authtoken,
      email: userData.username,
      password: userData.password
    }
    setLoader(true)
    try {
      const response = await Axios.post(
        endpoint + '/api/login_customer_submit',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setLoader(false)
      if (response.data.status === 'error') {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        setToken('true')
        dispatch(UserLoginResponse(response.data.b2b_agent));
        localStorage.setItem('token', response.data.b2b_agent.token)
      }
    } catch (error) {
      setLoader(false)
      console.error('Error:', error)
    }
  }
  const GetCountries = async () => {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      // 'Authorization': 'Bearer YourAccessToken', // Replace with your authorization token
      'Content-Type': 'application/json' // Specify content type if needed
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/allcountriesfetch',
        headers
      )

      // Handle the response data
      // setSignupData({
      //   ...signupData,
      //   country: response.data.countries[0]?.iso2
      // })
      var newcountry = response.data.countries.map(item => ({
        label: item.name,
        value:item.id,
        flag:item.iso2,
        code:item.phonecode
    }));
      setCountry(newcountry)
      // GetCountryCities(response.data.countries[0]?.id)
    } catch (error) {
      // Handle any errors
      console.error('Error:', error)
    }
  }
  const GetCountryCities = async id => {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      // 'Authorization': 'Bearer YourAccessToken', // Replace with your authorization token
      'Content-Type': 'application/json' // Specify content type if needed
    }
    const data = {
      id: id
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/countryCites_apis',
        data,
        headers
      )

      setCity(response.data.country)
      setsignUpCity(response.data.country[0]?.name)
      // setSignupData({ ...signupData, city: response.data.country[0]?.name })
    } catch (error) {
      // Handle any errors
      console.error('Error:', error)
    }
  }
  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }
  const HandleRegister = async (e) => {
    e.preventDefault();
    if (signupData.fname === '') {
      toast.error('Please Enter First Name.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signupData.lname === '') {
      toast.error('Please Enter Last Name.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signupData.email === '') {
      toast.error('Please Enter Your Email.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (!isValidEmail(signupData.email)) {
      toast.error('Please Enter a Valid Email.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signupData.cname === '') {
      toast.error('Please Enter Company Name.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signupData.caddress === '') {
      toast.error('Please Enter Company Address.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signupData.country === null) {
      toast.error('Please Select Country.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signUpCity === '') {
      toast.error('Please Select City.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signupData.phno === '') {
      toast.error('Please Enter Phone Number.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signupData.zcode === '') {
      toast.error('Please Enter Zip Code.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (signupData.pass === '') {
      toast.error('Please Enter Password.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }
    const data = {
      token:authtoken,
      email: signupData.email,
      password: signupData.pass,
      title: signupData.title,
      first_name: signupData.fname,
      last_name: signupData.lname,
      company_name: signupData.cname,
      company_address: signupData.caddress,
      country: signupData.country.label,
      city: signUpCity,
      phone_no: signupData.phno,
      zip_code: signupData.zcode,
      otp_code: ''
    }
    setLoader(true)
    try {
      const response = await Axios.post(
        endpoint + '/api/register/customer/submit',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setLoader(false)
      if (response.data.status === 'success') {
        setsignUp(!signUp)
        toast.success('Thank you for registration with ALHijaz Rooms.Our team will review your details and confirm your account with in 24 to 48 hours.', {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        setLoader(false)
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } catch (error) {
      setLoader(false)
      console.error('Error:', error)
    }
  }
  const Change = () => {
    setsignUp(!signUp)
  }

  const ShowHidePassword=()=>{
    setShowPassword(!showPassword)
  }
  return (
    <>
      <ToastContainer />
      {loader && <Loading />}
      {!signUp ? (
        <section class="vh-xxl-100">
        <div class="container h-100 d-flex px-0 px-sm-4">
          <div class="row justify-content-center align-items-center m-auto">
            <div class="col-12">
              <div class="bg-mode shadow rounded-3 overflow-hidden">
                <div class="row g-0">
                  
                  <div class="col-lg-6 d-flex align-items-center order-2 order-lg-1">
                    <div class="p-3 p-lg-5">
                      <img src={image} alt=""/>
                    </div>
                   
                    <div class="vr opacity-1 d-none d-lg-block"></div>
                  </div>
          
                 
                  <div class="col-lg-6 order-1">
                    <div class="p-4 p-sm-7">
                     
                      <a href="index-2.html">
                        <img class="h-50px mb-4" src={logo} alt="logo"/>
                      </a>
                     
                      <h1 class="mb-2 h3">Welcome back</h1>
                      <p class="mb-0">New here?<span style={{ color: 'rgb(0 88 255)',cursor:'pointer' }} onClick={Change}> Create an account</span></p>
          
                    
                      <form class="mt-4 text-start" onSubmit={HandleLogin}>
                       
                        <div class="mb-3">
                          <label class="form-label">Enter email </label>
                          <input type="email" onChange={handleChange} value={userData.username} placeholder='Enter Email' name='username' class="form-control"/>
                          
                        </div>
                       
                        <div class="mb-3 position-relative">
                          <label class="form-label">Enter password</label>
                          <input name='password' value={userData.password}  placeholder='Enter Password' onChange={handleChange} class="form-control fakepassword" type={`${showPassword?'text':'password'}`} id="psw-input"/>
                          <span class="position-absolute top-50 end-0 translate-middle-y p-0 mt-3">
                            <i onClick={ShowHidePassword} class={`fakepasswordicon fas ${showPassword?'fa-eye':'fa-eye-slash'} cursor-pointer p-2`}></i>
                          </span>
                        </div>
                       
                        {/* <div class="mb-3 d-sm-flex justify-content-between">
                          <div>
                            <input type="checkbox" class="form-check-input" id="rememberCheck"/>
                            <label class="form-check-label" for="rememberCheck">Remember me?</label>
                          </div>
                          <a href="forgot-password.html">Forgot password?</a>
                        </div> */}
                       
                        <div><button type="submit" class="btn btn-primary w-100 mb-0">Login</button></div>
          
                       
                        {/* <div class="position-relative my-4">
                          <hr/>
                          <p class="small bg-mode position-absolute top-50 start-50 translate-middle px-2">Or sign in with</p>
                        </div> */}
          
                       
                        {/* <div class="vstack gap-3">
                          <a href="#" class="btn btn-light mb-0"><i class="fab fa-fw fa-google text-google-icon me-2"></i>Sign in with Google</a>
                          <a href="#" class="btn btn-light mb-0"><i class="fab fa-fw fa-facebook-f text-facebook me-2"></i>Sign in with Facebook</a>
                        </div>
           */}
                       
                        <div class="text-primary-hover text-body mt-3 text-center"> Copyrights ©{moment().format('YYYY')} AlHijaz Rooms.</div>
                      </form>
                     
                    </div>		
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      ) :(
        <section class="vh-xxl-100">
	<div class="container h-100 d-flex px-0 px-sm-4">
		<div class="row justify-content-center align-items-center m-auto">
			<div class="col-12">
				<div class="bg-mode shadow rounded-3 overflow-hidden">
					<div class="row g-0">
						<div class="col-lg-6 d-md-flex align-items-center order-2 order-lg-1">
							<div class="p-3 p-lg-5">
								<img src={image} alt=""/>
							</div>
							<div class="vr opacity-1 d-none d-lg-block"></div>
						</div>
		
						<div class="col-lg-6 order-1">
							<div class="p-4 p-sm-6">
								<a href="index-2.html">
									<img class="h-50px mb-4" src={logo} alt="logo"/>
								</a>
								<h1 class="mb-2 h3">Create new account</h1>
								<p class="mb-0">Already a member?<span style={{ color: 'rgb(0 88 255)',cursor:'pointer' }} onClick={Change}> Log in</span></p>
		
								<form class="mt-4 text-start" onSubmit={HandleRegister}>
                <div class="row">
                  <div class="form-group col-md-6 mb-3">
                    <label class="form-label">Title</label>
                    <select  onChange={handleSignupChange} value={signupData.title} name='title' class="form-control" aria-label="Default select example">
                          <option value="MR">MR.</option>
                            <option value="MRS">MRS.</option>
                          <option value="MISS">MISS.</option>
                          </select>
                  </div>
                  <div class="form-group col-md-6 mb-3">
                    <label class="form-label">First Name</label>
                    <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.fname}
                              name='fname'
                              class='form-control'
                              required=''
                            />
                  </div>
                  <div class="form-group col-md-6 mb-3">
                  <label class="form-label">Last Name</label>
                  <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.lname}
                              name='lname'
                              class='form-control'
                              required=''
                            />
                  </div>
                  <div class="form-group col-md-6 mb-3">
                  <label class="form-label">Enter email </label>
                  <input
                              type='email'
                              onChange={handleSignupChange}
                              value={signupData.email}
                              name='email'
                              class='form-control'
                              required=''
                            />
                  </div>
                  <div class="form-group col-md-6 mb-3">
                  <label class="form-label">Enter password</label>
                  <input
                              type='password'
                              onChange={handleSignupChange}
                              value={signupData.pass}
                              name='pass'
                              class='form-control'
                              required=''
                            />
                  </div>
                  <div class="form-group col-md-6 mb-3">
                  <label class="form-label">Company Name</label>
                  <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.cname}
                              name='cname'
                              class='form-control'
                              required=''
                            />
                  </div>
                  <div class="form-group col-md-6 mb-3">
                  <label class="form-label">Company Address</label>
                  <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.caddress}
                              name='caddress'
                              class='form-control'
                              required=''
                            />
                  </div>
                  <div class="form-group col-md-6 mb-3">
                  <label class="form-label">Country</label>
                     <Select
                        value={signupData.country}
                        options={country}
                        onChange={handleSignupChangeCountry}
                        placeholder="Select a country"
                        getOptionLabel={option => (
                          <div>
                              <span class="iconify" data-icon={`twemoji:flag-${option.label.toLowerCase()}`} data-inline="false"></span>
                            {' '}{option.label}
                          </div>
                        )}
                        getOptionValue={option => option.label}
                      />
                  </div>
                  <div class="form-group col-md-6 mb-3">
                  <label class="form-label">City</label>
                  <select value={signUpCity} onChange={handleSignupChangeCity} name='city' class="form-control" aria-label="Default select example">
                    {city.map(item => (
                             <option key={item.id}  value={item.name}>
                          {item.name}
                         </option>
                         ))}
                     </select>
                  </div>
                  <div class="form-group col-md-6 mb-3">
                  <label class="form-label">Phone No</label>
                  <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.phno}
                              name='phno'
                              class='form-control'
                              onInput={(e) => (e.currentTarget.value = e.currentTarget.value .replace(/[^0-9.]/g, "") .replace(/(\..*?)\..*/g, "$1"))}
                              required=''
                            />
                  </div>
                  <div class="form-group col-md-6 mb-3">
                  <label class="form-label">Zip Code</label>
                  <input
                              type='text'
                              onChange={handleSignupChange}
                              value={signupData.zcode}
                              name='zcode'
                              class='form-control'
                              required=''
                            />
                  </div>
                </div>
									{/* <div class="mb-3">
										<input type="checkbox" class="form-check-input" id="rememberCheck"/>
										<label class="form-check-label" for="rememberCheck">Keep me signed in</label>
									</div> */}
									<div><button type="submit" class="btn btn-primary w-100 mb-0">Sign up</button></div>
		
									{/* <div class="position-relative my-4">
										<hr/>
										<p class="small position-absolute top-50 start-50 translate-middle bg-mode px-1 px-sm-2">Or sign in with</p>
									</div> */}
		
									{/* <div class="vstack gap-3">
										<a href="#" class="btn btn-light mb-0"><i class="fab fa-fw fa-google text-google-icon me-2"></i>Sign in with Google</a>
										<a href="#" class="btn btn-light mb-0"><i class="fab fa-fw fa-facebook-f text-facebook me-2"></i>Sign in with Facebook</a>
									</div>
		 */}
									<div class="text-primary-hover text-body mt-3 text-center"> Copyrights ©{moment().format('YYYY')} AlHijaz Rooms.</div>
								</form>
							</div>		
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
      )}
    </>
  )
}

export default Login
