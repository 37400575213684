import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer2 from "../Footer/Footer2";

function WithoutLayout({ children }){
    return(
        <>
           <Navbar />
            {children}
            <Footer2 />
        </>
    )
}

export default WithoutLayout;