import React, { useState ,useEffect,useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
} from '@fortawesome/free-solid-svg-icons'
import noUiSlider from 'nouislider'
import { RangeSlider } from 'rsuite';
import HotelCard from '../../Components/HotelCard/Hotelcard'
import { useSelector } from 'react-redux';
import Select from 'react-select'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import HotelSearch from '../../Components/SearchBar/HotelSearch';
import HotelSearchNew from '../../Components/SearchBar/HotelSearchNew'
import Layout from '../../Components/Layout/Layout'
import moment from 'moment';
import HotelMap from '../../Components/Googlemap/HotelMap'

// New Imports

import bgimg from '../../Images/New/topbg.jpg'
function Hotels ({ onLogout }) {
  var hotels1 = useSelector((state) => state.hotels.hotels);
  const [hotels, sethotels] = useState(hotels1);
  var hotelsSearchindex = useSelector((state) => state.hotels.hsearch);
  var minamounts = hotels.hotels_list.map(hotel => Number(hotel.min_price));
    var minValue2 = Math.min(...minamounts);
    var maxValue2 = Math.max(...minamounts);
  const [minValue, setMinValue] = useState(minValue2);
  const [maxValue, setMaxValue] = useState(maxValue2);
  const [hotelsSearchData, sethotelsSearchData] = useState(hotelsSearchindex);
  const [hotelNameFilter, setHotelNameFilter] = useState(null);
  const [baseCName, setBaseCName] = useState('GBP')
  const [rangeValue, setRangeValue] = useState([minValue2, maxValue2]);
  const [ShowPriceFilter, setShowPriceFilter] = useState(false);
    const [ShowMealTYpeFilter, setShowMealTYpeFilter] = useState(false);
    const [ShowRatingFilter, setShowRatingFilter] = useState(false);
    const [ShowFacilityFilter, setShowFacilityFilter] = useState(false);
  const [showModifySearch, setShowModifySearch] = useState(false);
  const [baseCurrency, setBaseCurrency] = useState([])
  const [hotelid, setHotelid] = useState("");
  const [showfilter, setShowfilter] = useState({rating:false,meal:false,facility:false});
  const [starRating, setStarRating] = useState({rating5:'0',rating4:'0',rating3:'0',rating2:'0',rating1:'0',type:'Stars'});
  const [mealRating, setMealRating] = useState({meal1:'',meal2:'',meal3:'',meal4:'',type:'meal'});
  const [selectedFacilities, setSelectedFacilities] = useState({ type: 'facility' }); // Initialize an empty array for selected items
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const handleCheckboxChange = (event) => {
    const { name, value } = event.target;
    setStarRating((prevDetails) => {
      const updatedStarRating = { ...prevDetails };
      if (updatedStarRating[name] !=="0") {
        updatedStarRating[name]="0";
      } else {
        updatedStarRating[name] =value;
      }
  
      return updatedStarRating;
    });
  };
  useEffect(() => {
    sethotelsSearchData(hotelsSearchindex);
    sethotels(hotels1)
    // hotels=hotels;
    
  }, [hotelsSearchindex,hotels1]);
  const handleMealTypeChange = (event) => {
    const { name, value } = event.target;

    setMealRating((prevDetails) => {
      const updatedStarRating = { ...prevDetails };
      if (updatedStarRating[name] !=="") {
        updatedStarRating[name]="";
      } else {
        updatedStarRating[name] =value;
      }
  
      return updatedStarRating;
    });
  };
  const handleCurrencyTopHotel = (hotelcurrency) => {
    // Handle logic when a hotel is selected in HotelCard
    setBaseCurrency(hotelcurrency); // Example logic to set the filtered hotels to the selected hotel
};
  const handleFacilityChange = (event) => {
    const { name, value } = event.target;
    const isSelected = selectedFacilities[name] === value;
    
    if (isSelected) {
      // If selected, remove it from the object
      const updatedFacilities = { ...selectedFacilities };
      delete updatedFacilities[name];
      setSelectedFacilities(updatedFacilities);
    } else {
      // If not selected, add it to the object
      setSelectedFacilities({ ...selectedFacilities, [name]: value });
    }

  };
  useEffect(() => {
  }, [hotelsSearchData,hotels1])
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;

    // Check if the slider element exists
    if (slider) {
      noUiSlider.create(slider, {
        start: [minValue, maxValue],
        connect: true,
        range: {
          min: minValue2,
          max: maxValue2
        }
      });

      slider.noUiSlider.on('update', (values, handle) => {
        if (handle === 0) {
          setMinValue(parseInt(values[handle]));
        } else {
          setMaxValue(parseInt(values[handle]));
        }
      });

      return () => slider.noUiSlider.destroy();
    }

    // If the slider element doesn't exist, do nothing
  }, [minValue, maxValue, minValue2, maxValue2]);
  const handleHotelFilter = inputValue => {
    setHotelid(inputValue.value);
    setHotelNameFilter(inputValue);
  };
  const StarFilter=()=>{
    setShowRatingFilter(false);
    setHotelid(starRating);
  };
const PriceFilter=()=>{
  setShowPriceFilter(false);
  var data={type:'price',min:minValue,max:maxValue};
  setHotelid(data);
}
  const MealFilter=()=>{
    setShowMealTYpeFilter(false);
    setHotelid(mealRating);
  };

  const FacilityFilter=()=>{
    setShowFacilityFilter(false);
    setHotelid(selectedFacilities);
  };
  const DisplayModifySearch=()=>{
    setShowModifySearch(!showModifySearch);
  };
  const Showfilter=(num)=>{
    if(num===1){
      setShowfilter(prevData => ({ ...prevData, rating: !prevData.rating }));
    }
    if(num===2){
      setShowfilter(prevData => ({ ...prevData, meal: !prevData.meal }));
    }
    if(num===3){
      setShowfilter(prevData => ({ ...prevData, facility: !prevData.facility }));

    }
  };
  const calculateMarkup = price => {
    if (hotels1.length !== 0) {
      let markupprice = 0
      let adminmarkupprice = 0
      let clientmarkupprice = 0
      let finalpricemarkup = Number(price)
      if(hotels1.hotels_list.length !==0){
      if (Number(hotels1.hotels_list[0].admin_markup) !== 0) {
        if (hotels1.hotels_list[0].admin_markup_type === 'Percentage') {
          markupprice =
            (price * Number(hotels1.hotels_list[0].admin_markup)) / 100
        } else {
          markupprice = Number(hotels1.hotels_list[0].admin_markup)
        }
        adminmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      if (Number(hotels1.hotels_list[0].customer_markup) !== 0) {
        if (hotels1.hotels_list[0].customer_markup_type === 'Percentage') {
          markupprice =
            (finalpricemarkup *
              Number(hotels1.hotels_list[0].customer_markup)) /
            100
        } else {
          markupprice = Number(hotels1.hotels_list[0].customer_markup)
        }
        clientmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      return finalpricemarkup.toFixed(0)
    }else{
      return 0
    }
     
    }
  }
  const TogglePriceFilter=()=>{
    setShowPriceFilter(!ShowPriceFilter);
  };
  const ToggleMealTypeFilter=()=>{
    setShowMealTYpeFilter(!ShowMealTYpeFilter);
  };
  const ToggleRatingFilter=()=>{
    setShowRatingFilter(!ShowRatingFilter);
  };
  const ToggleFacilityFilter=()=>{
    setShowFacilityFilter(!ShowFacilityFilter);
  };
  const handleChange = (newRangeValue) => {
    setRangeValue(newRangeValue);
    setMinValue(newRangeValue[0]);
    setMaxValue(newRangeValue[1]);
  };
  
  return (
    <>
       <Modal isOpen={ShowPriceFilter} toggle={TogglePriceFilter}>
          <ModalHeader toggle={TogglePriceFilter}>Price Filter</ModalHeader>
          <ModalBody>
          <div className='widget widget_price_filter'>
                <div className='mb-0'>
                  {/* <h3 className='form-label'>Price Level</h3> */}
                  <RangeSlider
                      value={rangeValue}
                      onChange={handleChange}
                      min={minValue2}
                      tooltip={false}
                      max={maxValue2}
                      step={1}
                    />                  <div className='pt-2'>
                    <div className='fw-bold mb-2'>
                      Min: <span id='kt_slider_basic_min'>{minValue.toFixed(0)} </span>
                    </div>
                    <div className='fw-bold mb-2'>
                      Max: <span id='kt_slider_basic_max'>{maxValue.toFixed(0)}</span>
                    </div>
                  </div>
                  <button onClick={PriceFilter} className='btn btn-warning m-2'>Apply</button>
                </div>
              </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={ShowMealTYpeFilter} toggle={ToggleMealTypeFilter}>
          <ModalHeader toggle={ToggleMealTypeFilter}>Meal Type Filter</ModalHeader>
          <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide' >                
                {/* <h3>Meal Type</h3> */}
                {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                </div>
                  <div>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal1' value='ROOM ONLY' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>  Room Only</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal2' value='BED AND BREAKFAST' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> BED AND BREAKFAST </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal3' value='HALF BOARD' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> HALF BOARD</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal4' value='Full BOARD' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Full BOARD</span>
                    </label>
                  </li>
                </ul>
                <button onClick={MealFilter} className='btn btn-warning m-2'>Apply</button>
                </div>
               
              </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={ShowRatingFilter} toggle={ToggleRatingFilter}>
          <ModalHeader toggle={ToggleRatingFilter}>Star Rating Filter</ModalHeader>
          <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide' onClick={()=>Showfilter(1)}>                
                  {/* <h3>Filter by Rating</h3> */}
                {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                </div>
                  <div>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' 
                      className='custom-textbox' 
                      onChange={handleCheckboxChange} name='rating5' value='5' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating4' value='4' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating3' value='3' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className='custom-textbox' onChange={handleCheckboxChange} name='rating2' value='2' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className='custom-textbox'  onChange={handleCheckboxChange} name='rating1' value='1' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                </ul>
                <button onClick={StarFilter} className='btn btn-warning m-2'>Apply</button>
                </div>
                 
              </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={ShowFacilityFilter} toggle={ToggleFacilityFilter}>
          <ModalHeader toggle={ToggleFacilityFilter}>Facility Filter</ModalHeader>
          <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide' onClick={()=>Showfilter(3)}>                
                {/* <h3>Facilities</h3> */}
                {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                </div>
                  <div>
                <ul> 
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility1' value='Wi-fi' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Wi-fi</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility2' value='Internet access' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Internet access </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility3' value='TV' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> TV</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility4' value='Wake-up service' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Wake-up service</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility4' value='Smoking rooms' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Smoking rooms</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility6' value='Wheelchair-accessible' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Wheelchair-accessible</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'  className='custom-textbox' onChange={handleFacilityChange} name='facility7' value='Laundry service' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Laundry service</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility8' value='Banquet hall' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Banquet hall</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility9' value='Non-smoking establishment' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Non-smoking establishment</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility10' value='Safe' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Safe</span>
                    </label>
                  </li>
                </ul>
                <div>
                <button onClick={FacilityFilter} className='btn btn-warning m-2'>Apply</button>
                </div>
                </div>
              
              </div>
          </ModalBody>
        </Modal>
    <Layout logout={onLogout}>
    <section class="pt-0 mt-2">
	<div class="container">
		<div class="rounded-3 p-3 p-sm-5" style={{backgroundImage:`url(${bgimg})`,backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat'}} >
			<div class="row my-2 my-xl-5"> 
				<div class="col-md-8 mx-auto"> 
					<h1 class="text-center text-white">{hotels1.hotels_list.length} Hotels in {hotelsSearchData.destination_name}</h1>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="pt-0">
	<div class="m-2">
		<div class="row">
    <div class="hstack gap-3 justify-content-between justify-content-md-end">
					<button class="btn mb-2 btn-primary-soft btn-primary-check mb-0 d-xl-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar">
						<i class="fa-solid fa-sliders-h me-1"></i> Show filters
					</button>
				</div>
			<aside class="col-xl-3 col-xxl-2">
     
				<div class="offcanvas-xl offcanvas-end" tabindex="-1" id="offcanvasSidebar" aria-labelledby="offcanvasSidebarLabel">
					<div class="offcanvas-header">
						<h5 class="offcanvas-title" id="offcanvasSidebarLabel">Advance Filters</h5>
						<button  type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close"></button>
					</div>
					<div class="offcanvas-body flex-column p-3 p-xl-0">
						<div class="rounded-3 shadow">
							<div class="card card-body rounded-0 rounded-top p-4">
								<h6 class="mb-2">Search by name</h6>
								<div class="col-12">
                <Select
                      value={hotelNameFilter}
                      onChange={handleHotelFilter}
                      options={hotels.hotels_list.map(option => ({
                        value: option.hotel_id,
                        label:option.hotel_name
                      }))}
                    />
								</div>
							</div>
							<hr class="my-0"/>
							<div class="card card-body rounded-0 rounded-top p-4">
								<h6 class="mb-2">Price Range</h6>
								<div class="col-12">
                <RangeSlider
                              value={rangeValue}
                              onChange={handleChange}
                              min={minValue2}
                              tooltip={false}
                              max={maxValue2}
                              step={1}
                            /> 
									<div className='d-flex fw-bold aligh-items-center justify-content-between'>
                    <p>Min</p>
                    <p>{calculateMarkup(minValue)}</p>
                  </div>
                  <div className='d-flex fw-bold aligh-items-center justify-content-between'>
                    <p>Max</p>
                    <p>{calculateMarkup(maxValue)}</p>
                  </div>
								</div>
                <button class="btn btn-primary mb-0 mt-2" onClick={PriceFilter}>Filter Result</button>
							</div>
              <hr class="my-0"/>
              <div class="card card-body rounded-0 rounded-top p-4">
								<h6 class="mb-2">Rating Star</h6>
								<div class="col-12">
                <div class="form-check">
										<input class="form-check-input"  onChange={handleCheckboxChange} name='rating5' value='5' type="checkbox"  id="hotelType1"/>
										<label class="form-check-label" for="hotelType1"> <FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /></label>
									</div>
                  <div class="form-check">
										<input class="form-check-input" type="checkbox"  onChange={handleCheckboxChange} name='rating4' value='4' id="hotelType1"/>
										<label class="form-check-label" for="hotelType1"> <FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /></label>
									</div>
                  <div class="form-check">
										<input class="form-check-input" type="checkbox"  onChange={handleCheckboxChange} name='rating3' value='3' id="hotelType1"/>
										<label class="form-check-label" for="hotelType1"> <FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /></label>
									</div>
                  <div class="form-check">
										<input class="form-check-input" type="checkbox"  onChange={handleCheckboxChange} name='rating2' value='2' id="hotelType1"/>
										<label class="form-check-label" for="hotelType1"> <FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /></label>
									</div>
                  <div class="form-check">
										<input class="form-check-input" type="checkbox"  onChange={handleCheckboxChange} name='rating1' value='1' id="hotelType1"/>
										<label class="form-check-label" for="hotelType1"> <FontAwesomeIcon icon={faStar} /></label>
									</div>
								</div>
                <button class="btn btn-primary mb-0 mt-2" onClick={StarFilter}>Filter Result</button>
							</div>
              <hr class="my-0"/>
              <div class="card card-body rounded-0 rounded-top p-4">
								<h6 class="mb-2">Meal Type</h6>
								<div class="col-12">
                <div class="form-check">
										<input class="form-check-input" type="checkbox"  onChange={handleMealTypeChange} name='meal1' value='ROOM ONLY' />
										<label class="form-check-label" > Room Only</label>
									</div>
                  <div class="form-check">
										<input class="form-check-input" type="checkbox"  onChange={handleMealTypeChange} name='meal2' value='BED AND BREAKFAST'/>
										<label class="form-check-label" for="hotelType1"> BED AND BREAKFAST</label>
									</div>
                  <div class="form-check">
										<input class="form-check-input" type="checkbox"  onChange={handleMealTypeChange} name='meal3' value='HALF BOARD'/>
										<label class="form-check-label" for="hotelType1"> HALF BOARD</label>
									</div>
                  <div class="form-check">
										<input class="form-check-input" type="checkbox"  onChange={handleMealTypeChange} name='meal4' value='Full BOARD'/>
										<label class="form-check-label" for="hotelType1"> Full BOARD</label>
									</div>
								</div>
                <button class="btn btn-primary mb-0 mt-2" onClick={MealFilter}>Filter Result</button>
							</div>
              <hr class="my-0"/>
              <div class="card card-body rounded-0 rounded-top p-4">
								<h6 class="mb-2">Facilities</h6>
								<div class="col-12">
                <div class="form-check">
										<input class="form-check-input" type="checkbox"  onChange={handleFacilityChange} name='facility1' value='Wi-fi'/>
										<label class="form-check-label" > Wi-fi</label>
									</div>
                  <div class="form-check">
										<input class="form-check-input" type="checkbox"  onChange={handleFacilityChange} name='facility2' value='Internet access'/>
										<label class="form-check-label" for="hotelType1"> Internet access</label>
									</div>
                  <div class="form-check">
										<input class="form-check-input" type="checkbox"  onChange={handleFacilityChange} name='facility3' value='TV'/>
										<label class="form-check-label" for="hotelType1"> TV</label>
									</div>
                  <div class="form-check">
										<input class="form-check-input" type="checkbox"  onChange={handleFacilityChange} name='facility4' value='Wake-up service'/>
										<label class="form-check-label" for="hotelType1"> Wake-up service</label>
									</div>
                  <div class="form-check">
										<input class="form-check-input" type="checkbox"  onChange={handleFacilityChange} name='facility4' value='Smoking rooms'/>
										<label class="form-check-label" for="hotelType1"> Smoking rooms</label>
									</div>
                  <div class="form-check">
										<input class="form-check-input" type="checkbox"  onChange={handleFacilityChange} name='facility6' value='Wheelchair-accessible'/>
										<label class="form-check-label" for="hotelType1"> Wheelchair-accessible</label>
									</div>
                  <div class="form-check">
										<input class="form-check-input" type="checkbox"  onChange={handleFacilityChange} name='facility7' value='Laundry service'/>
										<label class="form-check-label" for="hotelType1"> Laundry service</label>
									</div>
                  <div class="form-check">
										<input class="form-check-input" type="checkbox"  onChange={handleFacilityChange} name='facility8' value='Banquet hall' />
										<label class="form-check-label" for="hotelType1"> Banquet hall</label>
									</div>
								</div>
                <button class="btn btn-primary mb-0 mt-2" onClick={FacilityFilter}>Filter Result</button>
							</div>
						</div>
					</div>
				</div>
			</aside>

			<div class="col-xl-6 col-xxl-7">
          {hotels.hotels_list.length===0 ? (
                <h5 className='text-center'>Sorry! no hotels found in the given search.</h5>
            ):(
              <div>
                <HotelCard hotelid={hotelid} hotelDataNew={hotels} handlecurrencytop={handleCurrencyTopHotel}/>
              </div>
            )} 
			</div>
      <div class="col-xl-3 col-xxl-3">
      <HotelMap hotels={hotels} hotelsearch={hotelsSearchindex} newhotelbasecurrency={baseCurrency} filterhotel={handleHotelFilter}/>
      </div>
		</div> 
	</div>
</section>
      </Layout>
    </>
  )
}

export default Hotels
