import React, { useState,useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CustomerDomainName } from '../GlobalData/GlobalData';
import { Carousel, Button, Modal } from 'react-bootstrap';
import {
  faSearch,
  faSquareFull
} from '@fortawesome/free-solid-svg-icons'
function Images (props) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [showImage, setShowImage] = useState([]);
    const [showOneimage, setShowOneimage] = useState(true);
    const [showCarousel, setShowCarousel] = useState(false);
    const imageurl=CustomerDomainName();
    var imgaray=[];
    const imageData = props.data;
    const setting = props.setting;
    useEffect(()=>{
      setimages();
    },[]);
    const setimages=()=>{
      if(setting===1){
        var length=imageData.length;
        for(var i=0;i<length;i++){
          var url=imageurl+'public/uploads/package_imgs/'+imageData[i];
          imgaray.push(url);
        }
        setShowImage(imgaray);
      }else{
        setShowImage(imageData);
      }
    };
      const closeImage = () => {
        setSelectedImage(null)
      };
      const handleShowCarousel = () => {
        setShowCarousel(true);
      };
    
      const handleCloseCarousel = () => {
        setShowCarousel(false);
      };
      useEffect(() => {
        const checkWindowSize = () => {
          if(window.innerWidth <= 500){
            setShowOneimage(!showOneimage);
          }
        };
    
        // Initial check
        checkWindowSize();
    
        // Add event listener to check window size on resize
        window.addEventListener('resize', checkWindowSize);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', checkWindowSize);
        };
      }, []);
  return (
    <>
    <div className='imgpreview mt-2'>
     <Modal   show={showCarousel} onHide={handleCloseCarousel} dialogClassName='modal-90w'>
        <Modal.Body >
          <Carousel>
          {showImage.map((item,index)=>(
            <Carousel.Item key={index}>
              <img className='d-block w-100' src={item} alt='First slide' />
            </Carousel.Item>
          ))}
            
          </Carousel>
        </Modal.Body>
      </Modal>
      </div>
    {selectedImage && (
          <div className='image-lightbox' onClick={closeImage}>
            <img src={selectedImage} />
          </div>
        )}
  <section class="card-grid pt-0">
	<div class="container">
		<div class="row g-2">
			<div class="col-md-6">
				<a data-glightbox data-gallery="gallery" href="assets/images/gallery/14.jpg">
					<div class="card card-grid-lg card-element-hover card-overlay-hover overflow-hidden" style={{backgroundImage:`url(${showImage[0]})`,backgroundPosition:'center',backgroundSize:'cover'}} >
						<div class="hover-element position-absolute w-100 h-100">
						</div>
					</div>
				</a>
			</div>

			<div class="col-md-6">
				<div class="row g-2"> 
					<div class="col-12">
						<a data-glightbox data-gallery="gallery" href="assets/images/gallery/13.jpg">
							<div class="card card-grid-sm card-element-hover card-overlay-hover overflow-hidden" style={{backgroundImage:`url(${showImage[1]})`,backgroundPosition:'center',backgroundSize:'cover'}}>
								<div class="hover-element position-absolute w-100 h-100">
								</div>
							</div>
						</a>	
					</div>

					<div class="col-md-6">
						<a data-glightbox data-gallery="gallery" href="assets/images/gallery/12.jpg">
							<div class="card card-grid-sm card-element-hover card-overlay-hover overflow-hidden" style={{backgroundImage:`url(${showImage[2]})`,backgroundPosition:'center',backgroundSize:'cover'}}>
								<div class="hover-element position-absolute w-100 h-100">
								</div>
							</div>
						</a>	
					</div>

					<div class="col-md-6">
						<div class="card card-grid-sm overflow-hidden" style={{backgroundImage:`url(${showImage[3]})`,backgroundPosition:'center',backgroundSize:'cover'}}>
							<div class="bg-overlay bg-dark opacity-7"></div>
							<div class="card-img-overlay d-flex h-100 w-100">
								<h6 class="card-title m-auto fw-light text-decoration-underline"><a onClick={handleShowCarousel} class="text-white">View all</a></h6>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section> 
    </>
  )
}

export default Images
