import React, { useEffect, useState } from 'react'
import Layout from '../../Components/Layout/Layout'
import { useSelector } from 'react-redux'
import DashboardPage from './DashboardPage'
import Settingage from './Settingage'
import Axios from 'axios'
import { ApiEndPoint } from '../../Components/GlobalData/GlobalData'
import IncomeStatement from './IncomeStatement'
import MakePayment from './MakePayment'
import UserDashboard from './UserDashboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Routes,
  Route,
  useNavigate,
  NavLink,
  useLocation
} from 'react-router-dom'
import {
  faDashboard,
  faDollarSign,
  faFileInvoice,
  faGear,
  faHouse,
  faSignOut,
  faUpload
} from '@fortawesome/free-solid-svg-icons'
function Dashboard ({ onLogout }) {
  const baseurl = ApiEndPoint()
  const navigate = useNavigate()
  const UserDetail = useSelector(state => state.hotels.UserLoginRes)
  const [tentitiveBookings, setTentitiveBookings] = useState([])
  const [invoiceList, setInvoiceList] = useState([])
  const [dashboardData, setDashboardData] = useState([])
  const [incomList, setIncomList] = useState([])
  useEffect(() => {
    GetUserBooking()
  }, [])
  const logout = () => {
    onLogout()
  }
  const GetUserBooking = async () => {
    const data = {
      token: UserDetail.token,
      b2b_agent_id: UserDetail.id
    }

    try {
      const response = await Axios.post(
        baseurl + '/api/view_customer_hotel_booking',
        data
      )
      console.log(response)
      if (response.data.message === 'success') {
        setTentitiveBookings(response.data.tentative_Hotel_Bookings)
        setInvoiceList(response.data.inv_details)
        const mergedArray = [
          ...response.data.hotels_bookings.map(obj => ({
            ...obj,
            domain_name: 'Hotel Booking'
          })),
          ...response.data.invoice_Bookings.map(obj => ({
            ...obj,
            domain_name: 'Inovice Booking'
          })),
        ]
        setDashboardData(mergedArray)
        const confirmlist = response.data.inv_details.filter(
          item => item.status === 'CONFIRMED'
        )
        setIncomList(confirmlist)
      }
    } catch (error) {
      console.log(error)
      console.error('Error:', error)
    }
  }
  const location = useLocation()
  const getNavLinkClass = path => {
    return location.pathname === path ? 'active' : ''
  }
  return (
    <>
      <Layout logout={onLogout}>
        <section class='pt-3'>
          <div class='container-fluid'>
            <div class='row g-2 g-lg-4'>
              <div class='col-lg-4 col-xl-3'>
                <div
                  class='offcanvas-lg offcanvas-end'
                  tabindex='-1'
                  id='offcanvasSidebar'
                >
                  <div class='offcanvas-header justify-content-end pb-2'>
                    <button
                      type='button'
                      class='btn-close'
                      data-bs-dismiss='offcanvas'
                      data-bs-target='#offcanvasSidebar'
                      aria-label='Close'
                    ></button>
                  </div>

                  <div class='offcanvas-body p-3 p-lg-0'>
                    <div class='card bg-light w-100'>
                      <div class='position-absolute top-0 end-0 p-3'>
                        <a
                          href='#'
                          class='text-primary-hover'
                          data-bs-toggle='tooltip'
                          data-bs-title='Edit profile'
                        >
                          <i class='bi bi-pencil-square'></i>
                        </a>
                      </div>

                      <div class='card-body p-3'>
                        <div class='text-center mb-3'>
                          <h6 class='mb-0'>
                            {UserDetail.first_name} {UserDetail.last_name}
                          </h6>
                          <p class='text-reset text-primary-hover small'>
                            {UserDetail.email}
                          </p>
                          <hr />
                        </div>

                        <ul className='nav nav-pills-primary-soft flex-column'>
                          <li className='nav-item'>
                            <NavLink
                              to='/agent_dashboard'
                              className='text-decoration-none'
                            >
                              <p
                                style={{ cursor: 'pointer', fontSize: '1.2em' }}
                                className={`nav-link ${getNavLinkClass(
                                  '/agent_dashboard'
                                )}`}
                              >
                                <FontAwesomeIcon icon={faDashboard} /> Dashboard
                              </p>
                            </NavLink>
                          </li>
                          <li className='nav-item'>
                            <NavLink
                              to='/agent_dashboard/bookings'
                              className='text-decoration-none'
                            >
                              <p
                                style={{ cursor: 'pointer', fontSize: '1.2em' }}
                                className={`nav-link ${getNavLinkClass(
                                  '/agent_dashboard/bookings'
                                )}`}
                              >
                                <FontAwesomeIcon icon={faFileInvoice} /> My Bookings
                              </p>
                            </NavLink>
                          </li>
                          <li className='nav-item'>
                            <p
                              style={{ cursor: 'pointer', fontSize: '1.2em' }}
                              onClick={() => navigate('income-statement')}
                              className={`nav-link ${getNavLinkClass(
                                '/agent_dashboard/income-statement'
                              )}`}
                            >
                              <FontAwesomeIcon icon={faDollarSign} /> Income
                              Statement
                            </p>
                          </li>
                          <li className='nav-item'>
                            <p
                              style={{ cursor: 'pointer', fontSize: '1.2em' }}
                              onClick={() => navigate('make-payment')}
                              className={`nav-link ${getNavLinkClass(
                                '/agent_dashboard/make-payment'
                              )}`}
                            >
                              <FontAwesomeIcon icon={faUpload} /> Make Payment
                            </p>
                          </li>
                          <li className='nav-item'>
                            <p
                              style={{ cursor: 'pointer', fontSize: '1.2em' }}
                              onClick={() => navigate('settings')}
                              className={`nav-link ${getNavLinkClass(
                                '/agent_dashboard/settings'
                              )}`}
                            >
                              <FontAwesomeIcon icon={faGear} /> Settings
                            </p>
                          </li>
                          <li className='nav-item'>
                            <p
                              style={{ cursor: 'pointer', fontSize: '1.2em' }}
                              onClick={logout}
                              className='nav-link text-danger bg-danger-soft-hover'
                            >
                              <FontAwesomeIcon icon={faSignOut} /> Sign Out
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class='col-lg-8 col-xl-9 ps-xl-5 '>
              <div class="d-grid mb-0 d-lg-none w-100">
					<button class="btn btn-primary mb-4" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar">
						<i class="fas fa-sliders-h"></i> Menu
					</button>
				</div>
                <div className='row '>
                  <div className='col-lg-12 cold-md-12'>
                    <Routes>UserDashboard
                      <Route
                        index
                        element={
                          <UserDashboard  invoiceListData={dashboardData} />
                        }
                      />
                      <Route
                        path='bookings'
                        element={
                          <DashboardPage invoiceListData={dashboardData} />
                        }
                      />
                      <Route
                        path='income-statement'
                        element={
                          <IncomeStatement invoiceListData={incomList} />
                        }
                      />
                      <Route
                        path='make-payment'
                        element={
                          <MakePayment tentativebookins={tentitiveBookings} />
                        }
                      />
                      <Route
                        path='settings'
                        element={<Settingage userData={UserDetail} />}
                      />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Dashboard
