import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import Axios from 'axios'
import moment from 'moment'
import Loading from '../Loading/Loader'
import { useDispatch } from 'react-redux'
import {
  ApiEndPoint,
  Hotelapitoken,
  CurrencyConverter
} from '../GlobalData/GlobalData'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { fetchHotelDetail } from '../../Redux/Actions/actions'
import { toast,ToastContainer } from 'react-toastify'
function HotelCard ({ hotelid, hotelDataNew, handlecurrencytop }) {
  const dispatch = useDispatch()
  var endpoint = ApiEndPoint()
  var apitoken = Hotelapitoken()
  const [hotelsData, setHotelsData] = useState(hotelDataNew)
  const ReduxSearchData = useSelector(state => state.hotels.hsearch)
  const [searchData, setSearchData] = useState({
    checkin: '',
    checkout: '',
    adult: 0,
    child: 0
  })
  var filteredHotels = []
  const navigate = useNavigate()
  const [hotels, setHotels] = useState(hotelsData)
  const [numberOfNights, setNumberOfNights] = useState('')
  const [Loadingpage, setLoadingpage] = useState(false)
  const [visibleHotels, setVisibleHotels] = useState(10)
  const [hotelDetails, setHotelDetails] = useState({})
  const [loading, setLoading] = useState(false)
  const [baseCName, setBaseCName] = useState('GBP')
  const [baseCurrency, setBaseCurrency] = useState([])
  const [showHotels, setShowHotels] = useState([])
  const [hotelsListing, setHotelsListing] = useState(
    hotelsData.hotels_list.sort(
      (a, b) => Number(a.min_price) - Number(b.min_price)
    )
  )
  const [showPrice, setShowPrice] = useState(false)
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  var [currentPage, setCurrentPage] = useState(1)
  var TransferPerPage = 20 // Number of hotels to display per page
  var indexOfLastTransfer = currentPage * TransferPerPage
  var indexOfFirstTransfer = indexOfLastTransfer - TransferPerPage
  var currentHotels = hotelsListing.slice(
    indexOfFirstTransfer,
    indexOfLastTransfer
  )
  var pagesCount = Math.ceil(hotelsListing.length / TransferPerPage)
  var validCurrentPage = currentPage < 1 ? 1 : currentPage
  var maxPagesToShow = 5
  var startHotelIndex = indexOfFirstTransfer + 1
  var endHotelIndex = Math.min(indexOfLastTransfer, hotelsListing.length)
  const paginate = pageNumber => {
    window.scrollTo(0, 0)
    setCurrentPage(pageNumber)
  }

  const renderPaginationItems = () => {
    const items = []
    let startPage = currentPage - Math.floor(maxPagesToShow / 2)
    startPage = Math.max(startPage, 1)
    const endPage = Math.min(startPage + maxPagesToShow - 1, pagesCount)

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink onClick={() => paginate(i)} href='#'>
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }

    return items
  }
  useEffect(() => {
    // Adjust the current page if it exceeds the total number of pages
    if (currentPage < 1) {
      setCurrentPage(1)
    } else if (currentPage > pagesCount) {
      setCurrentPage(pagesCount)
    }
  }, [currentPage, pagesCount])
  useEffect(() => {
    setHotelsData(hotelDataNew)
    setHotelsListing(hotelDataNew.hotels_list)
  }, [hotelDataNew, ReduxSearchData])
  useEffect(() => {
    let Checkin = moment(ReduxSearchData.check_in)
    let checkout = moment(ReduxSearchData.check_out)
    let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
    setNumberOfNights(daysBetween)
    setCurrentPage(1)
  }, [hotelsListing])

  useEffect(() => {
    if (hotelid !== '') {
      setLoadingpage(true)
      if (typeof hotelid === 'number') {
        filteredHotels = hotelDataNew.hotels_list.filter(
          x => x.hotel_id === Number(hotelid)
        )
        setHotelsListing(filteredHotels)
        setLoadingpage(false)
      } else if (hotelid.type === 'Stars') {
        const filteredHotels = hotelDataNew.hotels_list.filter(hotel => {
          const hotelRating = hotel.stars_rating
          if (hotelRating !== '') {
            return Object.keys(hotelid).some(
              ratingKey => Number(hotelRating) === Number(hotelid[ratingKey])
            )
          }
        })
        setHotelsListing(filteredHotels)
        // filteredHotels.forEach(hotel => {
        //   fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        // })
        setLoadingpage(false)
      } else if (hotelid.type === 'meal') {
        const filteredHotels = hotelDataNew.hotels_list.filter(hotel => {
          // Check if any room in the hotel's rooms_options array has the specified boardtype
          return hotel.rooms_options.some(room => {
            for (const mealKey in hotelid) {
              if (room.board_id === hotelid[mealKey]) {
                return true // Found a match, include the hotel
              }
            }
            return false // No match found for any meal key
          })
        })
        setHotelsListing(filteredHotels)
        setHotelDetails({})
        // filteredHotels.forEach(hotel => {
        //   fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        // })
        setLoadingpage(false)
      } else if (hotelid.type === 'facility') {
        const filteredHotels = []
        hotelDataNew.hotels_list.forEach(hotel => {
          Facilityfilter(hotel.hotel_id, hotel.hotel_provider, metaData => {
            // Check if the hotel has the desired facilities (e.g., wifi and safe).
            for (const mealKey in hotelid) {
              if (metaData.facilities.includes(hotelid[mealKey])) {
                filteredHotels.push(hotel)
              }
            }
          })
        })
        setLoadingpage(false)
        setHotelDetails({})
        // filteredHotels.forEach(hotel => {
        //   fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        // })
        setHotelsListing(filteredHotels)
      } else if (hotelid.type === 'price') {
        const filteredHotels = hotelDataNew.hotels_list.filter(hotel => {
          const price = hotel.min_price
          return price >= hotelid.min && price <= hotelid.max
        })
        setHotelsListing(filteredHotels)
        setHotelDetails({})
        // filteredHotels.forEach(hotel => {
        //   fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        // })
        setLoadingpage(false)
      }
    }
  }, [hotelid])

  const Facilityfilter = async (hotelId, provider, callback) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: apitoken
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/hotels/mata_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      callback(response.data.details_data)
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    localStorage.setItem('AllHotelCurr',JSON.stringify(null));
    filterHotels();
    const fetchCurrencies = async () => {
      const currencies = [];
      hotelsData.hotels_list.forEach(hotel => {
        if (!currencies.includes(hotel.hotel_curreny)) {
          currencies.push(hotel.hotel_curreny);
        }
      });
      const currencyResponse =await AllCurrency(currencies);
      const result =makeArrayNull(currencyResponse)
      if(result===null){
        localStorage.setItem('AllHotelCurr',null);
      }else{
        localStorage.setItem('AllHotelCurr',JSON.stringify(currencyResponse));
      }
      // setCurrencyData(currencyResponse);
      setSearchData(prevdata => ({
        ...prevdata,
        adult: ReduxSearchData.adult,
        child: ReduxSearchData.child,
        checkin: moment(ReduxSearchData.check_in).format('Do MMM '),
        checkout: moment(ReduxSearchData.check_out).format('Do MMM ')
      }));
    };
    fetchCurrencies();
  }, [])


  const filterHotels = () => {
    const newHotels = hotels.hotels_list.filter(hotel => {
      // Check if there is at least one room option with a cancellation amount equal to the room price
      return hotel.rooms_options.some(roomOption => {
        if (roomOption.cancliation_policy_arr.length > 0) {
          // Assuming there can be multiple cancellation policies, check if any of them has an amount equal to the room price
          return roomOption.cancliation_policy_arr.some(
            policy =>
              parseFloat(policy.amount) ===
              parseFloat(roomOption.rooms_total_price)
          )
        }
        return false
      })
    })

    setShowHotels(newHotels)
  }

  const RoomDetailPage = async (id, index) => {
    const hotelRoomdetail = hotelDataNew.hotels_list.filter(
      item => item.hotel_id == id
    )
    setLoadingpage(true)
    try {
      const data = {
        token: apitoken,
        hotel_search_data: JSON.stringify(hotelRoomdetail[0]),
        hotel_code: String(id)
      }
      const response = await Axios.post(
        endpoint + '/api/hotels/details_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      if(response.data!==''){
        setLoadingpage(false)
        dispatch(fetchHotelDetail(response.data.hotel_details))
        navigate(`/hotel_detail/${id}`, { state: { index } })
      }else{
        setLoadingpage(false)
        toast.info('Something went wrong. Please select another hotel or try after some time thanks.')
      }
      // Handle the API response here
      
    } catch (error) {
      // Handle errors here
      setLoadingpage(false)
      console.error('Error:', error)
    }
  }

  const fetchHotelDetails = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: apitoken
    }

    try {
      const response = await Axios.post(
        endpoint + '/api/hotels/mata_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setHotelDetails(prevDetails => ({
        ...prevDetails,
        [hotelId]: response.data
      }))
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  }
  useEffect(() => {
    currentHotels.forEach(hotel => {
      if (!hotelDetails[hotel.hotel_id]) {
        fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
      }
    })
  }, [currentPage, hotelsListing])

  const renderPrice = (price, currency) => {
    var currencyData = JSON.parse(localStorage.getItem('AllHotelCurr'));
    var selectedcurr = localStorage.getItem('DefaultCurrency')

    if (selectedcurr === currency || currencyData === null) {
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2)
      }
      return Number(price)
    } else {
      var filterroomcurrdata = currencyData.filter(
        item => item.base_code === currency
      )
      if (CurrencyRates === undefined) {
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName] // Use square brackets to access the property
        var baseprice = Number(gbpprice) * Number(price)
      } else {
        var select123 = CurrencyRates.selectedcurrency
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName]
        var baseprice1 = Number(gbpprice) * Number(price)
        const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
        var baseprice = Number(gbpprice2) * Number(baseprice1)
      }
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2)
      }
      return baseprice
    }
  }
  const AllCurrency = async (currencyArray) => {
    const token = CurrencyConverter(); // Assumes this function returns the API token
    const requests = currencyArray.map(currency => {
      const config = {
        method: 'get',
        url: `https://v6.exchangerate-api.com/v6/${token}/latest/${currency}`, // API URL
        maxBodyLength: Infinity,
        headers: {}
      };
  
      return Axios.request(config)
        .then(response => {
          return response.data; // Return the data from the response
        })
        .catch(error => {
          console.error(error);
          return null; // Return null for failed requests
        });
    });
  
    const results = await Promise.all(requests);
  
    const newdatcurr = results.filter(response => response !== null); // Filter out failed requests
  
    if (newdatcurr.length > 0) {
      setShowPrice(true); // Show price if at least one request succeeded
    } else {
      setShowPrice(false); // Hide price if all requests failed
    }
  
    return newdatcurr;
  };
  function makeArrayNull (arr) {
    // Check if every element in the array is an empty array
    if (arr.every(element => Array.isArray(element) && element.length === 0)) {
      return null
    }
    return arr
  }
  const calculateMarkup = (
    price,
    adminmarkup,
    admintype,
    clientmarkup,
    clienttype
  ) => {
    let markupprice = 0
    let adminmarkupprice = 0
    let clientmarkupprice = 0
    let finalpricemarkup = Number(price)
    if (Number(adminmarkup) !== 0) {
      if (admintype === 'Percentage') {
        markupprice = (price * Number(adminmarkup)) / 100
      } else {
        markupprice = Number(adminmarkup)
      }
      adminmarkupprice = markupprice
      finalpricemarkup += markupprice
      // savemarkup.admin=adminmarkupprice;
      // savemarkup.final=finalpricemarkup;
    }
    if (Number(clientmarkup) !== 0) {
      if (clienttype === 'Percentage') {
        markupprice = (finalpricemarkup * Number(clientmarkup)) / 100
      } else {
        markupprice = Number(clientmarkup)
      }
      clientmarkupprice = markupprice
      finalpricemarkup += markupprice
    }
    if (Number(finalpricemarkup) % 1 !== 0) {
      return Number(finalpricemarkup).toFixed(2)
    }
    return finalpricemarkup
  }

  return (
    <>
    <ToastContainer/>
      {Loadingpage && <Loading />}
      <div className='fw-bold  p-2 mb-2 rounded-2 card shadow '>
        Showing {startHotelIndex} to {endHotelIndex} of {hotelsListing.length}{' '}
        hotels
      </div>
      <div class='vstack gap-4'>
        {currentHotels.map((item, index) => (
          <div key={index} class='card shadow p-2'>
            <div class='row g-0'>
              <div class='col-md-5 position-relative'>
                <div class='position-absolute top-0 start-0 z-index-1 m-2'>
                  <div class='badge text-bg-danger'>
                    {' '}
                    {item.rooms_options.length} Rooms Available{' '}
                  </div>
                </div>

                {hotelDetails[item.hotel_id] &&
                hotelDetails[item.hotel_id].details_data &&
                hotelDetails[item.hotel_id].details_data.image ? (
                  <img
                    src={hotelDetails[item.hotel_id].details_data.image}
                    alt=''
                    style={{
                      height: '16em',
                      width: '100%',
                      objectFit: 'cover',
                      borderRadius: '9px'
                    }}
                  />
                ) : (
                  <div className='css-loader'></div>
                )}

                {/* <div class="tiny-slider arrow-round arrow-xs arrow-dark overflow-hidden rounded-2">
									<div class="tiny-slider-inner" data-autoplay="false" data-arrow="true" data-dots="false" data-items="1">
										

									</div>
								</div> */}
              </div>

              <div class='col-md-7'>
                <div class='card-body py-md-2 d-flex flex-column h-100 position-relative'>
                  <div class='d-flex justify-content-between align-items-center'>
                    <ul class='list-inline mb-0'>
                      {item.stars_rating === '' ? (
                        <li class='list-inline-item me-0 small'>No Rating</li>
                      ) : (
                        Array(item.stars_rating)
                          .fill(0)
                          .map((_, index) => (
                            <li key={index} class='list-inline-item me-0 small'>
                              <i class='fa-solid fa-star text-warning'></i>
                            </li>
                          ))
                      )}
                    </ul>

                    {/* <ul class='list-inline mb-0 z-index-2'>
                      <li class='list-inline-item'>
                        <a href='#' class='btn btn-sm btn-round btn-light'>
                          <i class='fa-solid fa-fw fa-heart'></i>
                        </a>
                      </li>
                      <li class='list-inline-item dropdown'>
                        <a
                          href='#'
                          class='btn btn-sm btn-round btn-light'
                          role='button'
                          id='dropdownShare'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          <i class='fa-solid fa-fw fa-share-alt'></i>
                        </a>
                        <ul
                          class='dropdown-menu dropdown-menu-end min-w-auto shadow rounded'
                          aria-labelledby='dropdownShare'
                        >
                          <li>
                            <a class='dropdown-item' href='#'>
                              <i class='fab fa-twitter-square me-2'></i>Twitter
                            </a>
                          </li>
                          <li>
                            <a class='dropdown-item' href='#'>
                              <i class='fab fa-facebook-square me-2'></i>
                              Facebook
                            </a>
                          </li>
                          <li>
                            <a class='dropdown-item' href='#'>
                              <i class='fab fa-linkedin me-2'></i>LinkedIn
                            </a>
                          </li>
                          <li>
                            <a class='dropdown-item' href='#'>
                              <i class='fa-solid fa-copy me-2'></i>Copy link
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul> */}
                  </div>

                  <h5 class='card-title mb-1'>
                    <a>{item.hotel_name}</a>
                  </h5>
                  {hotelDetails[item.hotel_id] && (
                    <small>
                      <i class='bi bi-geo-alt me-2'>
                        {' '}
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                      </i>
                      {hotelDetails[item.hotel_id]?.details_data?.address}
                    </small>
                  )}
                  <div className='d-flex justify-content-between align-items-center'>
                  {hotelDetails[item.hotel_id] ? (
                    <ul style={{listStyle:'none'}} class='p-0 mt-3'>
                      {hotelDetails[item.hotel_id]?.details_data?.facilities
                        .length > 0 &&
                        hotelDetails[item.hotel_id].details_data.facilities.slice(0,3).map(
                          (facility, index) => (
                            <li key={index} class='nav-item text-success'>
                              {facility}
                            </li>
                          )
                        )}
                    </ul>
                  ) : (
                    <div className='placeholder-glow'>
                      <span className='placeholder col-12'></span>
                      <span className='placeholder col-12'></span>
                      <span className='placeholder col-6'></span>
                    </div>
                  )}
                  {showPrice ? (
                      <div class=''>
                        <h5 class='fw-bold mb-0 me-1'>
                          {CurrencyRates === undefined
                            ? baseCName
                            : CurrencyRates.selectedcurrency}{' '}
                          {Number(
                            renderPrice(
                              calculateMarkup(
                                item.min_price,
                                item.admin_markup,
                                item.admin_markup_type,
                                item.customer_markup,
                                item.customer_markup_type
                              ),
                              item?.hotel_curreny
                            )
                          )}
                          <span style={{fontSize:'12px',color:'#808080a6'}} class='text-decoration-line-through mb-0'>
                          {item?.max_price_Actual &&
                          item?.max_price_Actual !== 0 ? (
                            <>
                              {Number(
                                renderPrice(
                                  calculateMarkup(
                                    item.max_price_Actual,
                                    item.admin_markup,
                                    item.admin_markup_type,
                                    item.customer_markup,
                                    item.customer_markup_type
                                  ),
                                  item?.hotel_curreny
                                )
                              )}
                            </>
                          ) : null}
                        </span>
                        </h5>
                        <span class='mb-0 me-2'></span>
                        for {numberOfNights} night
                      </div>
                    ) : (
                      <div class=''>
                        <h5 class='fw-bold mb-0 me-1'>
                          {item?.hotel_curreny}{' '}
                          {Number(
                            calculateMarkup(
                              item.min_price,
                              item.admin_markup,
                              item.admin_markup_type,
                              item.customer_markup,
                              item.customer_markup_type
                            )
                          )}
                           <span style={{fontSize:'12px',color:'#808080a6'}} class='text-decoration-line-through mb-0'>
                          {item?.max_price_Actual &&
                          item?.max_price_Actual !== 0 ? (
                            <>
                              {Number(
                                calculateMarkup(
                                  item.max_price_Actual,
                                  item.admin_markup,
                                  item.admin_markup_type,
                                  item.customer_markup,
                                  item.customer_markup_type
                                )
                              )}
                            </>
                          ) : null}
                        </span>
                        </h5>
                        <span class='mb-0 me-2'></span>
                        for {numberOfNights} night
                      </div>
                    )}
                    
                  </div>
                  <div class=' align-items-end  '>
                    <div class=' text-center mt-sm-0'>
                      {showPrice ? (
                        <>
                          {CurrencyRates === undefined
                            ? baseCName
                            : CurrencyRates.selectedcurrency}{' '}
                          {Number(
                            renderPrice(
                              calculateMarkup(
                                item.min_price,
                                item.admin_markup,
                                item.admin_markup_type,
                                item.customer_markup,
                                item.customer_markup_type
                              ),
                              item?.hotel_curreny
                            )
                          ) / Number(numberOfNights).toFixed(2)}
                          /Night
                        </>
                      ) : (
                        <span>
                          {item?.hotel_curreny}{' '}
                          {Number(
                            calculateMarkup(
                              item.min_price,
                              item.admin_markup,
                              item.admin_markup_type,
                              item.customer_markup,
                              item.customer_markup_type
                            ) / Number(numberOfNights)
                          ).toFixed(2)}{' '}
                          /Night
                        </span>
                      )}
                      <p
                        onClick={() => RoomDetailPage(item.hotel_id, index)}
                        class='btn btn-sm cursor-pointer btn-dark mb-0 w-100'
                      >
                        Select Hotel
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <nav class='d-flex justify-content-center' aria-label='navigation'>
          <Pagination aria-label='Page navigation example '>
            <PaginationItem disabled={currentPage === 1}>
              <PaginationLink
                previous
                onClick={() => paginate(validCurrentPage - 1)}
              />
            </PaginationItem>
            {renderPaginationItems()}
            <PaginationItem disabled={currentPage === pagesCount}>
              <PaginationLink
                next
                onClick={() => paginate(validCurrentPage + 1)}
              />
            </PaginationItem>
          </Pagination>
        </nav>
      </div>
    </>
  )
}

export default HotelCard
